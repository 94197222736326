
import {defineComponent, ref, onMounted} from "vue";
import {Guest} from "@/Types/Guest/Guest";
import {GuestCompanion} from "@/Types/Guest/GuestCompanion";
import {GuestService} from "@/Services/GuestService";
import Toast from "@/components/App/Toast.vue";

export default defineComponent({
  name: "GuestRegistration",
  components: {Toast},
  setup() {
    const guest = ref<Guest>(new Guest())
    const companions = ref<GuestCompanion[]>([])
    const showToast = ref<boolean>(false)
    const toastType = ref<'info'|'error'>('info')
    const toastMessage = ref<string>('')
    const guestDataId = ref<number | null>(null)

    const addCompanion = () => {
      if (companions.value.length < 6) {
        companions.value.push(new GuestCompanion())
      }
    }

    const removeCompanion = (index: number) => {
      if (companions.value.length > 0) {
        companions.value.splice(index, 1)
      }
    }

    const createNewConfirm = async () => {
      if (guest.value.name !== null && guest.value.email !== null && guest.value.phone !== null) {
        if (guestDataId.value) {
          await GuestService.updateGuestConfirm(guest.value, companions.value, guestDataId.value)
              .then(() => {
                showToast.value = true
                toastMessage.value = "Deine Änderungen wurden erfolgreich gespeichert"
                toastType.value = 'info'
              })
              .catch(() => {
                showToast.value = true
                toastMessage.value = "Deine Änderungen konnten nicht gespeichert werden"
                toastType.value = 'error'
              })
        } else {
          await GuestService.createGuestConfirm(guest.value, companions.value)
          .then(() => {
            showToast.value = true
            toastMessage.value = "Deine Anmeldung wurde erfolgreich gespeichert"
            toastType.value = 'info'
          })
          .catch(() => {
            showToast.value = true
            toastMessage.value = "Deine Anmeldung konnt nicht gespeichert werden"
            toastType.value = 'error'
          })
        }
      }
    }

    onMounted(async () => {
      await GuestService.loadGuestConfirm().then(response => {
        console.log('the response', response)
        const attributes = response.data[0].attributes

        companions.value = attributes.companions

        guest.value.name = attributes.name
        guest.value.phone = attributes.phone
        guest.value.email = attributes.email
        guest.value.vegan = attributes.vegan
        guest.value.normal = attributes.normal
        guest.value.vegetarien = attributes.vegetarien

        guestDataId.value = response.data[0].id
      })
    })

    return {guest, companions, addCompanion, removeCompanion, showToast, toastMessage, toastType, createNewConfirm}
  }
})
