import {Guest} from "@/Types/Guest/Guest";
import {GuestCompanion} from "@/Types/Guest/GuestCompanion";
import axios from "axios";
import {LocalStorageHelper} from "@/Services/LocalStorageHelper";

class InternalGuestService {
    private _apiHost = 'https://wedding.backend-hamperl.de/api/'

    async loadGuestConfirm() {
        const token = LocalStorageHelper.getItem('token')
        const user = LocalStorageHelper.getItem('userData')
        if (token && user) {
            const userData = JSON.parse(user)
            const guestResponse = await axios.get(`${this._apiHost}wedding-confirms?filters[plus_pax_parent][$eq]=${userData.username}&populate=%2A`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })

            if (guestResponse) {
                return guestResponse.data
            }
        }
    }
    async createGuestConfirm(mainGuest: Guest, companions: GuestCompanion[]): Promise<void> {
        const token = LocalStorageHelper.getItem('token')
        const user = LocalStorageHelper.getItem('userData')
        if (token && user) {
            await axios.post(`${this._apiHost}wedding-confirms`,
        {
            data: {
                name: mainGuest.name,
                phone: mainGuest.phone,
                email: mainGuest.email,
                normal: mainGuest.normal,
                vegan: mainGuest.vegan,
                vegetarien: mainGuest.vegetarien,
                allergien: mainGuest.allergien,
                allergien_name: mainGuest.allergien_name,
                is_plus_pax: false,
                plus_pax_parent: JSON.parse(user).username,
                companions: companions
            }
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        }

    }

    async updateGuestConfirm(mainGuest: Guest, companions: GuestCompanion[], id: number): Promise<void> {
        const token = LocalStorageHelper.getItem('token')
        const user = LocalStorageHelper.getItem('userData')
        if (token && user) {
            await axios.put(`${this._apiHost}wedding-confirms/${id}`,
                {
                    data: {
                        name: mainGuest.name,
                        phone: mainGuest.phone,
                        email: mainGuest.email,
                        normal: mainGuest.normal,
                        vegan: mainGuest.vegan,
                        vegetarien: mainGuest.vegetarien,
                        allergien: mainGuest.allergien,
                        allergien_name: mainGuest.allergien_name,
                        is_plus_pax: false,
                        plus_pax_parent: JSON.parse(user).username,
                        companions: companions
                    }
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
        }

    }
}

export const GuestService = new InternalGuestService()