
import {defineComponent, onMounted, ref} from "vue";
import {LocalStorageHelper} from "@/Services/LocalStorageHelper";
import {useRouter} from "vue-router";
import {RemoteUser} from "@/Types/User/RemoteUserCreated";
import Loader from "@/components/App/Loader.vue";
import GuestRegistration from "@/components/Guest/GuestRegistration.vue";

export default defineComponent({
  name: 'GuestProfile',
  components: {GuestRegistration, Loader},
  setup() {
    const router = useRouter()
    const user = ref<RemoteUser | null>(null)
    const loading = ref<boolean>(true)

    onMounted(() => {
      const token = LocalStorageHelper.getItem('token')
      const userData = LocalStorageHelper.getItem('userData')
      if (!token) {
        router.push('/login')
      }
      if (userData) {
        user.value = JSON.parse(userData)
        loading.value = false
      }
    })

    return {user, loading}
  }
})
