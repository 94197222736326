export class Guest {
    name = ''
    phone = ''
    email = ''
    normal = true
    vegan = false
    vegetarien = false
    allergien = false
    allergien_name = ''
    is_plus_pax = false
    plus_pax_parent = ''
}